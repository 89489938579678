<template>
  <div>
    <v-tabs v-model="tab" class="ma-0 py-5 px-10" slider-color="primary">
      <v-tab>
        <span>{{ $t('settings.onlineBooking.general') }}</span>
      </v-tab>
      <v-tab>
        <span>{{ $t('edit.gallery') }}</span>
      </v-tab>
      <v-tab>
        <span v-if="articleType !== 'product'">Booker</span>
        <span v-else>Détails</span>
      </v-tab>
      <v-tab disabled>
        <span>Pass Beauté</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="1" class="mx-5 pa-5">
        <v-row no-gutters style="margin: 10px">
          <v-col cols="12" md="6">
            <multilang-text-field
              v-model="name"
              :placeholder="$t('articles.articleName')"
              :label="$t('articles.articleName')"
              class="mb-6"
              :class="{ empty: !controli18n($i18n.locale, name) }"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              class="pl-3"
              type="text"
              label="Slug"
              v-model="slug"
              :class="{ empty: !slug }"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  outlined
                  class="pl-3"
                  type="number"
                  step="0.01"
                  :label="$t('articles.priceTTC')"
                  v-model.number="prices"
                  :placeholder="$t('other.price')"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <multilang-textarea
              v-model="description.short"
              :placeholder="$t('edit.shortDescription')"
              :label="$t('edit.shortDescription')"
              style="width: 100%"
            />
          </v-col>
          <v-col cols="12">
            <multilang-textarea
              v-model="description.long"
              :placeholder="$t('edit.longDescription')"
              :label="$t('edit.longDescription')"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="mx-5 pa-5">
        <div>
          <v-switch v-model="useColor" label="Utiliser une couleur de fond" />
        </div>
        <div
          v-if="
            !useColor ||
            (['default'].includes(
              $store.state.auth.company.themes['articles'].id
            ) &&
              $store.state.auth.company.themes['articles'].useBarMode)
          "
        >
          <div>
            <images-selector
              v-model="images"
              :size="size"
              :updateData="updateData"
              :categories="imagesArticles"
              :imageSizes="sizes"
            />
            <file-explorer
              :images="imagesArticles"
              :images-already-selected="images"
              :loading="loadingBanque"
              class="mt-5"
              @uploadImages="uploadImages"
            />
          </div>
        </div>
        <div v-if="useColor" class="mt-6">
          <v-color-picker
            v-model="backgroundColor"
            :swatches="swatches"
            show-swatches
            mode="hexa"
            class="ma-auto"
          />
        </div>
      </v-tab-item>
      <v-tab-item class="mx-5 pa-5">
        <template v-if="articleType !== 'product'">
          <v-row>
            <v-col cols="12">
              <v-switch
                v-model="booker.active"
                :label="
                  articleType === 'service'
                    ? $t('articles.activateArticlesForOnlineBooking')
                    : $t('articles.activateArticlesForOnlineSale')
                "
              />
            </v-col>
          </v-row>
          <v-row v-if="articleType === 'service'">
            <v-col cols="6">
              <v-text-field
                outlined
                type="number"
                step="10"
                :label="$t('articles.durationService')"
                v-model.number="booker.duration"
                :placeholder="$t('articles.durationService')"
                suffix="min"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                outlined
                type="number"
                step="10"
                :label="$t('articles.breakTimeAfterService')"
                v-model.number="booker.pause"
                :placeholder="$t('articles.breakTimeAfterService')"
                suffix="min"
              />
            </v-col>
            <v-col cols="6">
              <v-radio-group v-model="booker.displayDuration">
                <v-radio
                  key="d0"
                  label="Afficher la durée seulement"
                  value="0"
                />
                <v-radio
                  key="d1"
                  label="Afficher la durée + la pause"
                  value="1"
                />
                <v-radio key="d2" label="Ne pas afficher la durée" value="2" />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-switch
                v-model="booker.inGift"
                label="Activer l'article pour les bons cadeaux"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-switch
                v-model="booker.appointment.overridePaymentSettings"
                :label="$t('articles.modifyPercentagePaidArticle')"
                v-if="articleType === 'service'"
              />
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
                v-if="
                  articleType === 'service' &&
                  booker.appointment.overridePaymentSettings
                "
              >
                <pourcentage
                  v-model="booker.appointment.percent"
                  :label="$t('articles.percentageToBePaid')"
                  style="margin-right: 10px"
                />

                <v-text-field
                  v-model.number="booker.appointment.moneyToPay"
                  :label="$t('articles.amountToBePaid')"
                  type="number"
                  step="0.01"
                  outlined
                  style="margin-left: 10px"
                />
              </div>
              <v-text-field
                outlined
                type="number"
                v-model.number="booker.appointment.nbEmployeeRequired"
                :label="$t('articles.numberPractitioner')"
                v-if="articleType === 'service'"
              />
              <v-text-field
                outlined
                type="number"
                v-model.number="booker.appointment.nbCustomerRequired"
                :label="$t('articles.numberClients')"
                v-if="articleType === 'service'"
              />
              <v-switch
                v-model="booker.appointment.showAlerts"
                :label="$t('articles.showAlertMessages')"
                v-if="articleType === 'service'"
              />
              <div v-if="booker.appointment.showAlerts" class="mb-12">
                <div>
                  <strong>{{ $t('customMails.alertMessage') }}</strong>
                </div>
                <multilang-editor v-model="booker.alert" />
              </div>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <v-select
            v-model="booker.tags"
            :items="tabExistingTags"
            multiple
            outlined
            label="Tags"
            :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
          >
            <template v-slot:selection="{ item }">
              <v-chip close @click:close="deleteTag(item)">
                {{ controli18n($i18n.locale, item) }}
              </v-chip>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item
                :class="{
                  'grey lighten-2': booker.tags.find(
                    (t) =>
                      controli18n($i18n.locale, t) ===
                      controli18n($i18n.locale, item)
                  ),
                }"
                @click="
                  !booker.tags.find(
                    (t) =>
                      controli18n($i18n.locale, t) ===
                      controli18n($i18n.locale, item)
                  )
                    ? addTag(item)
                    : deleteTag(item)
                "
              >
                <v-list-item-title>
                  {{ controli18n($i18n.locale, item) }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:append-item>
              <v-divider class="my-2" />
              <v-list-item @click="dialogCreateTag = true">
                <v-list-item-title>
                  {{ $t('categories.createNewTag') }}
                  <v-icon
                    style="border: 2px solid #777"
                    class="rounded-circle ml-5"
                  >
                    mdi-plus
                  </v-icon>
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>

          <v-dialog v-model="dialogCreateTag" width="500">
            <v-card v-if="dialogCreateTag" class="pa-5">
              <multilang-text-field
                label="Tag"
                v-model="tagToAdd"
                @keypress.enter="addTag(tagToAdd)"
              />
              <div class="d-flex align-center justify-space-around pt-3">
                <v-btn text color="red" @click="cancelAddTag">
                  {{ $t('edit.cancel') }}
                </v-btn>
                <v-btn
                  class="mx-5"
                  color="green"
                  dark
                  @click="addTag(tagToAdd)"
                >
                  {{ $t('other.add') }}
                </v-btn>
              </div>
            </v-card>
          </v-dialog>
        </template>
      </v-tab-item>
      <v-tab-item class="mx-5 pa-5">
        <v-switch v-model="passport.active" :label="$t('articles.activated')" />
        <div v-if="passport.active">
          <v-switch v-model="retouche" :label="$t('articles.retouch')" />
          <v-card>
            <v-data-table
              v-model="consentementSelected"
              hide-default-footer
              show-select
              height="300px"
              single-select
              calculate-widths
              item-key="value"
              fixed-header
              :items-per-page="consentementItemByPage"
              :headers="headersConsentement"
              :items="consentementItems"
            >
              <template v-slot:top>
                <div class="d-flex">
                  <div class="mx-auto">
                    <v-btn
                      type="button"
                      @click="newConsentPopup = true"
                      text
                      tile
                    >
                      <v-icon left>mdi-plus</v-icon>
                      {{ $t('icons.new') }}
                    </v-btn>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <icon-display
                  type="flat"
                  :toolTip="$t('articles.editConsent', { consent: item.text })"
                  :icon="$icones.edit"
                />
              </template>
            </v-data-table>
          </v-card>
          <div>
            <v-dialog width="950" v-model="newConsentPopup">
              <action-modal
                :title="$t('articles.createNewConsent')"
                :icon="$icones.passbeaute"
                @actionModal="actionConsentement"
              >
                <template v-slot:actionModalContent>
                  <div style="height: 50vh; overflow-y: auto">
                    <consentement-form ref="consentementForm" />
                  </div>
                </template>
              </action-modal>
            </v-dialog>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>

    <v-overlay :value="loading" class="d-flex justify-center align-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Vue from 'vue'
import { PassportSDK } from '@/packages/artebeaute-sdk/src'
import uploadsHelpers from '@/helpers/uploads'
import swal from 'sweetalert'
import slugify from 'slugify'
import MultilangTextarea from '@/components/helpers/multilangTextarea.vue'
import MultilangTextField from '@/components/helpers/multilangTextField.vue'
import ConsentementForm from '@/components/database/consentements/consentementForm.vue'
import IconDisplay from '../../helpers/iconDisplay.vue'
import icones from '@/helpers/icones'
import ImagesSelector from '@/components/helpers/imagesSelector.vue'
import ActionModal from '@/components/helpers/actionModal.vue'
import MultilangEditor from '@/components/helpers/multilangEditor'
import Pourcentage from '../../helpers/pourcentage'
import banqueImages from '../../../helpers/banqueImages'
import FileExplorer from '../../images/fileExplorer'
import { controli18n } from '../../../helpers/langs'
import imageSizes from '@/helpers/imageSizes'

// import collectionItemFormVue from "../collections/collectionItemForm.vue";

export default {
  components: {
    Pourcentage,
    MultilangEditor,
    MultilangTextarea,
    MultilangTextField,
    ConsentementForm,
    IconDisplay,
    ImagesSelector,
    ActionModal,
    FileExplorer,
  },
  props: {
    article: {
      type: Object,
      default: null,
    },
    submit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recalculateMoney: true,
      recalculatePercent: true,
      tab: null,
      loading: false,
      slug: this.article ? this.article.slug : 'ppppp',
      consentementSelected: [],
      consentementItemByPage: -1,
      imageData: null,
      name: this.article ? this.article.name : null,
      description: this.article ? this.article.description : {},
      retouche: this.article ? this.article.retouche : false,
      useColor:
        this.article && this.article.booker
          ? this.article.booker.useColor
          : false,
      backgroundColor:
        this.article && this.article.booker
          ? this.article.booker.backgroundColor
          : '#333333',
      booker: this.article ? this.article.booker : { vat: 20 },
      passport: this.article ? this.article.passport : {},
      prices: this.article ? this.article.prices : 0.0,
      images: this.article ? this.article.images : [],
      consentements: [],
      newConsentPopup: false,
      imagesArticles: [],
      loadingBanque: false,
      updateData: [],
      tagToAdd: null,
      dialogCreateTag: false,
      swatches: [
        ['#FFFFFF', '#000000', '#999999'],
        ['#FFFF00', '#FF0000', '#0000FF'],
        ['#00FF00', '#FF7F00', '#50026A'],
        ['#fa2c96', '#D4AF37', '#7B3F00'],
      ],
    }
  },
  computed: {
    consentementItems() {
      return this.consentements.map((c) => {
        return {
          value: c.id,
          text: c.name,
        }
      })
    },
    articleType() {
      const types = {
        1: 'service',
        2: 'product',
        4: 'service',
      }

      return types[this.article.art_type]
    },
    nickname() {
      return this.$store.state.auth.company.nickname
    },
    headersConsentement() {
      return [
        {
          text: this.$t('articles.selectConsent'),
          value: 'text',
        },
        {
          text: this.$t('database.tables.actions'),
          value: 'actions',
          width: 100,
          divider: true,
          sortable: false,
        },
      ]
    },
    $icones() {
      return icones().getIcones()
    },

    fusionArticles: function () {
      return this.$store.getters['articles/fusionArticle']()
    },
    onlineArticles() {
      return this.fusionArticles
        .filter((a) => [2].includes(parseInt(a.art_type)))
        .filter((a) => !!a.id)
    },
    tabExistingTags() {
      const tags = []
      this.onlineArticles.forEach((art) => {
        if (art.booker.tags) {
          art.booker.tags.forEach((tag) => {
            if (
              !tags.find(
                (t) =>
                  this.controli18n(this.$i18n.locale, t) ===
                  this.controli18n(this.$i18n.locale, tag)
              )
            ) {
              tags.push(tag)
            }
          })
        }
      })

      if (this.booker.tags && this.booker.tags.length) {
        this.booker.tags.forEach((tag) => {
          if (
            !tags.find(
              (t) =>
                this.controli18n(this.$i18n.locale, t) ===
                this.controli18n(this.$i18n.locale, tag)
            )
          ) {
            tags.push(tag)
          }
        })
      }

      return tags
    },
    size() {
      return this.$store.state.auth.company &&
        this.$store.state.auth.company.themes &&
        this.$store.state.auth.company.themes.servicePresentation &&
        this.$store.state.auth.company.themes.servicePresentation.id
        ? imageSizes.services[
            this.$store.state.auth.company.themes.servicePresentation.id
          ]
        : imageSizes.services.default
    },
    sizes() {
      return imageSizes.services
    },
  },
  created() {
    // if (!this.slug) {
    //   this.slug = slugify(this.name.fr).toLowerCase()
    // }

    if (this.articleType !== 'product') {
      // Appointment
      if (!this.booker.appointment) {
        Vue.set(this.booker, 'appointment', {})
      }
      if (!this.booker.appointment.nbEmployeeRequired) {
        Vue.set(this.booker.appointment, 'nbEmployeeRequired', 1)
      }
      if (!this.booker.appointment.nbCustomerRequired) {
        Vue.set(this.booker.appointment, 'nbCustomerRequired', 1)
      }
      if (!this.booker.appointment.showAlerts) {
        Vue.set(this.booker.appointment, 'showAlerts', true)
      }
      if (!this.booker.appointment.percent) {
        Vue.set(this.booker.appointment, 'percent', 100)
      }
      if (!this.booker.appointment.moneyToPay) {
        Vue.set(this.booker.appointment, 'moneyToPay', this.prices)
      }
      if (!this.booker.alert) {
        Vue.set(this.booker, 'alert', '')
      }
      if (typeof this.booker.inGift === 'undefined') {
        Vue.set(this.booker, 'inGift', true)
      }
      if (typeof this.booker.displayDuration === 'undefined') {
        Vue.set(this.booker, 'displayDuration', '1')
      }
    } else {
      if (!this.booker.tags) {
        Vue.set(this.booker, 'tags', [])
      }
      // Appointment
      if (!this.booker.appointment) {
        Vue.set(this.booker, 'appointment', {})
      }
      if (!this.booker.appointment.nbEmployeeRequired) {
        Vue.set(this.booker.appointment, 'nbEmployeeRequired', 1)
      }
      if (!this.booker.appointment.nbCustomerRequired) {
        Vue.set(this.booker.appointment, 'nbCustomerRequired', 1)
      }
      if (!this.booker.appointment.showAlerts) {
        Vue.set(this.booker.appointment, 'showAlerts', true)
      }
      if (!this.booker.appointment.percent) {
        Vue.set(this.booker.appointment, 'percent', 100)
      }
      if (!this.booker.appointment.moneyToPay) {
        Vue.set(this.booker.appointment, 'moneyToPay', this.prices)
      }
      if (!this.booker.alert) {
        Vue.set(this.booker, 'alert', '')
      }
      if (typeof this.booker.inGift === 'undefined') {
        Vue.set(this.booker, 'inGift', true)
      }
      if (typeof this.booker.displayDuration === 'undefined') {
        Vue.set(this.booker, 'displayDuration', '1')
      }
    }
  },
  watch: {
    'booker.appointment.moneyToPay'() {
      if (!this.recalculatePercent) {
        this.recalculatePercent = true
        return
      }

      this.recalculateMoney = false
      this.recalculateMPercent()
    },
    'booker.appointment.percent'() {
      if (!this.recalculateMoney) {
        this.recalculateMoney = true
        return
      }

      this.recalculatePercent = false
      this.recalculateMoneyToPay()
    },
    submit: async function (val) {
      if (val) {
        this.loading = true
        const response = await this.handleForm()

        if (response.error) {
          this.loading = false
          this.$emit('isSubmit', false)
          return swal({
            text: response.message,
            icon: 'error',
          })
        }

        this.loading = false

        this.$emit('isSubmit', true)
      }
    },
    // article: {
    //   handler: function(val) {
    //     this.name = val ? val.name : null
    //     // this.slug = slugify(val.name.fr).toLowerCase()
    //     this.description = val ? val.description : {}
    //     this.retouche = val ? val.retouche : false
    //     this.booker = val ? val.booker : {}
    //     this.passport = val ? val.passport : {}
    //     this.prices = val ? val.prices : 0.0
    //     this.imageData = null
    //     this.images = val ? val.images : []
    //   },
    //   deep: true
    // }
    // name: {
    //   handler: function(val) {
    //     this.slug = slugify(val.fr).toLowerCase()
    //   },
    //   deep: true
    // }
  },
  async mounted() {
    // if (!this.slug) {
    //   this.slug = slugify(this.name.fr).toLowerCase()
    // }

    let ourImages,
      yourImages = []
    this.loadingBanque = true

    try {
      await banqueImages.getArticles().then((res) => {
        ourImages = res
      })
      await banqueImages.getThem(this.nickname, 'services').then((res) => {
        yourImages = res
      })
    } catch {
      //
    }

    yourImages.forEach((y) => {
      if (ourImages.find((o) => o.title === y.title)) {
        const src = ourImages.find((o) => o.title === y.title).src
        src.push(...y.src)
      } else {
        ourImages.push(y)
      }
    })

    ourImages.sort((a, b) => {
      if (a.title < b.title) return -1
      else if (b.title < a.title) return 1
      return 0
    })

    this.imagesArticles = ourImages
    this.loadingBanque = false

    try {
      const consentementsResponse =
        await PassportSDK.Consentements.getAllConsentements()
      this.consentements = consentementsResponse.consentements

      if (this.passport.consentementID) {
        this.consentementSelected = this.consentements.reduce((prev, cur) => {
          if (cur.id === this.passport.consentementID) {
            prev.push({ value: cur.id, text: cur.name })
          }
          return prev
        }, [])
      }
    } catch (err) {
      console.log('err :>> ', err)
    }
  },
  methods: {
    controli18n,
    recalculateMoneyToPay() {
      const percent = this.booker.appointment.percent
      const articlePrice = this.prices

      const newMoneyToPay = (articlePrice * percent) / 100

      this.booker.appointment.moneyToPay = parseFloat(newMoneyToPay)
    },
    recalculateMPercent() {
      const articlePrice = this.prices
      const newMoneyToPay = this.booker.appointment.moneyToPay

      const newPercent = (newMoneyToPay * 100) / articlePrice

      this.booker.appointment.percent = parseFloat(newPercent)
    },
    getSlugify(val) {
      this.slug = slugify(val).toLowerCase()
    },
    actionConsentement(e) {
      if (e) {
        this.saveConsent()
      } else {
        this.newConsentPopup = false
      }
    },
    uploadImages(images) {
      images.forEach((img) => {
        if (!this.images.find((i) => i.url === img.url)) {
          this.images.push(img)
        }
      })
      this.updateData = this.images
    },
    async saveConsent() {
      const newConsentData = this.$refs.consentementForm.getContent()

      try {
        const consentResponse =
          await PassportSDK.Consentements.createConsentement(newConsentData)
        const newConsent = consentResponse.consentement

        this.consentements.push(newConsent)

        this.newConsentPopup = false

        this.passport.consentementID = newConsent.id
      } catch (err) {
        swal({
          title: this.$t('articles.unableCreateConsent'),
          icon: 'error',
        })
      }
    },
    async handleForm() {
      let imagesUrl = []

      try {
        for (let i = 0; i < this.images.length; i++) {
          if (this.images[i].new) {
            const image = this.images[i].blob
            // const imageLazy = this.images[i].blobLazy
            const fileName = uploadsHelpers.randomName()

            /*
            const imageUrl = await uploadsHelpers.uploadSingleBase64(
              image,
              'admin/artebeaute/services',
              fileName
            )

            const lazyImageUrl = await uploadsHelpers.uploadSingleBase64(
              imageLazy,
              'admin/artebeaute/services',
              fileName + '-lazy'
            )*/

            const directory = this.images[i].category
              ? 'imagekit/eden/bank/services/' + this.images[i].category
              : 'imagekit/eden/services'

            const imageUrl = await uploadsHelpers.uploadSingleBase64(
              image,
              directory,
              fileName,
              this.nickname
            )

            imagesUrl.push({
              url: imageUrl,
              description: this.images[i].description,
              alt: this.images[i].alt,
            })
          } else {
            imagesUrl.push(this.images[i])
          }
        }
      } catch (err) {
        console.log(err)
        return {
          error: true,
          message: this.$t('settings.anErrorOccuredImages'),
        }
      }

      if (this.passport.active) {
        this.passport.consentementID =
          this.consentementSelected.length > 0
            ? this.consentementSelected[0].value
            : null
      }

      if (this.article) {
        let booker = {}
        if (this.articleType !== 'product') {
          booker = {
            active: this.booker.active,
            alert: this.booker.alert,
            appointment: this.booker.appointment,
            articles_link: this.booker.articles_link,
            cabin_capacity: this.booker.cabin_capacity,
            duration: this.booker.duration,
            inGift: this.booker.inGift,
            pause: this.booker.pause,
            displayDuration: this.booker.displayDuration,
          }
        } else {
          booker = {
            tags: this.booker.tags,
          }
        }

        booker.useColor = this.useColor
        booker.backgroundColor = this.backgroundColor

        const result = await this.$store.dispatch('articles/updateArticle', {
          article: this.article,
          data: {
            artebeauteId: this.article.artebeauteId,
            type: this.article.art_type,
            active: this.article.active,
            prices: this.prices,
            name: this.name,
            slug: slugify(this.slug, {
              strict: true,
            }).toLowerCase(),
            description: this.description,
            retouche: this.retouche,
            booker,
            passport: this.passport,
            images: imagesUrl,
          },
        })
        if (result.error) {
          if (result.code === 409) {
            return {
              error: true,
              message: this.$t('edit.slugMustBeUnique'),
            }
          }
          return {
            error: true,
            message: this.$t('articles.unableUpdateArticles'),
          }
        }

        return {
          success: true,
        }
      } else {
        return {
          error: true,
          message: this.$t('articles.articleCreationNotEnabled'),
        }
        // const result = await this.$store.dispatch("categories/createCategory", {
        //   artebeauteId: this.article.artebeauteId,
        //     type: this.article.type,
        //     active: this.article.active,
        //     prices: this.prices,
        //     name: this.name,
        //     description: this.description,
        //     retouche: this.retouche,
        //     booker: this.booker,
        //     passport: this.passport,
        //     images: [
        //       {
        //         src: imageURL,
        //       },
        //     ],
        // });
        // if (!result) {
        //   swal({
        //     title: "Impossible de créer la catégorie.",
        //     icon: "error",
        //   });
        // } else {
        //   this.$emit("onSuccess");
        // }
      }
    },

    addTag(tag) {
      if (
        this.controli18n(this.$i18n.locale, tag) &&
        !this.booker.tags.find(
          (t) =>
            this.controli18n(this.$i18n.locale, t) ===
            this.controli18n(this.$i18n.locale, tag)
        )
      ) {
        this.booker.tags.push(tag)
      }
      this.dialogCreateTag = false
      this.tagToAdd = null
    },
    deleteTag(tag) {
      const index = this.booker.tags.findIndex(
        (t) =>
          t === tag ||
          controli18n(this.$i18n.locale, t) ===
            controli18n(this.$i18n.locale, tag)
      )
      if (index > -1) {
        this.booker.tags.splice(index, 1)
      }
    },
    cancelAddTag() {
      this.tagToAdd = null
      this.dialogCreateTag = false
    },
  },
}
</script>

<style>
.blockText {
  display: block;
  border-left-style: solid;
  border-left-width: 6px;
  border-left-color: black;
}
</style>
